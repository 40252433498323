import { modifyClass } from "./modifyClass";
import { checkClass } from "./checkClass";

export const slideMenu = () => {
  const menuBlandBtn = document.getElementById("menuBland");
  const menuCategoryBtn = document.getElementById("menuCategory");
  const menuMotifBtn = document.getElementById("menuMotif");
  const menuGuideBtn = document.getElementById("menuGuide");
  const hamburgerMenuIcon = document.getElementById("hambMenu");
  const backBtn = document.querySelectorAll(".back-btn");
  const searchIcon = document.getElementById("searchIcon");
  //const body = document.body;

  menuBlandBtn.addEventListener("click", function() {
    modifyClass("open", "remove", ".open:not(#brandChildList)");
    modifyClass("open", "toggle", "#brandChildList");
    hamburgerCheckClass();
  });

  menuCategoryBtn.addEventListener("click", function() {
    modifyClass("open", "remove", ".open:not(#categoryChildList)");
    modifyClass("open", "toggle", "#categoryChildList");
    hamburgerCheckClass();
  });

  menuMotifBtn.addEventListener("click", function() {
    modifyClass("open", "remove", ".open:not(#motifChildList)");
    modifyClass("open", "toggle", "#motifChildList");
    hamburgerCheckClass();
  });

  menuGuideBtn.addEventListener("click", function() {
    modifyClass("open", "remove", ".open:not(#guideChildList)");
    modifyClass("open", "toggle", "#guideChildList");
    hamburgerCheckClass();
  });

  searchIcon.addEventListener("click", function() {
    modifyClass("open", "remove", ".open:not(#search)");
    modifyClass("open", "toggle", "#search");
  });

  hamburgerMenuIcon.addEventListener("click", function() {
    modifyClass("open", "remove", "#search");
    if (checkClass("open")) {
      modifyClass("open", "remove", ".open");
    } else {
      modifyClass("open", "add", "#globalNavLeft", "#hambMenu");
    }
  });

  for (let i of backBtn) {
    i.addEventListener("click", function() {
      modifyClass("open", "remove", ".global-nav-menu-list");
      modifyClass("open", "add", "#globalNavLeft");
      hamburgerCheckClass();
    });
  }

  function hamburgerCheckClass() {
    if (checkClass("open")) {
      modifyClass("open", "add", "#hambMenu");
    } else {
      modifyClass("open", "remove", "#hambMenu");
    }
  }
};
