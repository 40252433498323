/**
 * 対象のセレクターに対してクラスの変更
 * @param {string} className - modify class name
 * @param {string} type - classList method
 * @param {string} targetSelector - target selector
 */
export const modifyClass = (className, type, ...targetSelector) => {
  const target = [];

  for (let selectors of [...targetSelector]) {
    const selector = document.querySelectorAll(`${selectors}`);
    target.unshift(...selector);
  }

  for (let element of [...target]) {
    switch (type) {
      case "add":
        element.classList.add(className);
        break;

      case "remove":
        element.classList.remove(className);
        break;

      case "toggle":
        element.classList.toggle(className);
        break;

      default:
        break;
    }
  }
};
