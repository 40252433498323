export function fixedHeader() {

  function fixedHeaderAnimetion() {

    const breakpoint = 400;

    function getScrollAmout() {
      return window.pageYOffset;
    }

    function toggleClass() {
      const header = document.getElementById('header');
      const body = document.body;

      if (getScrollAmout() >= 180 && getScrollAmout() <= breakpoint) {
        header.classList.add('js-ready-fixedHeader');
        body.style.paddingTop = "180px";
      } else if (getScrollAmout() >= 180 && getScrollAmout() >= breakpoint) {
        header.classList.add('js-ready-fixedHeader');
        header.classList.add('js-fixedHeader');
        body.style.paddingTop = "180px";
      } else {
        header.classList.remove('js-ready-fixedHeader');
        header.classList.remove('js-fixedHeader');
        body.style.paddingTop = "0";
      }
    }

    toggleClass();
  }

  function run() {
    let ticking = false;
    if (!ticking) {
      window.requestAnimationFrame(() => {
        fixedHeaderAnimetion();
        ticking = false;
      });
      ticking = true;
    }
  }

  window.addEventListener('scroll', run, { passive: true })

}
