import Swiper from "./module/swiper";
import { fixedHeader } from "./module/fixedHeader";
import { slideMenu } from "./module/slideMenu";
import { matchHeight } from "./module/matchHeight";
import { resizeEvent } from "./module/resizeEvent";
import $ from "jquery";

$(function() {
  $.ajax({
    type: 'GET',
    url: 'https://graph.facebook.com/v3.0/17841403734507818?fields=name%2Cmedia.limit(12)%7Bcaption%2Clike_count%2Cmedia_url%2Cpermalink%2Ctimestamp%2Cusername%7D&access_token=EABBzdE1DHZBABALppxiOKf6yAhxRrzFkS4AZCw5cpvkZCHKK0qQb5rk2Ld9hn9NxH4wc7MtpBxvMtAyBXuSPI2gOwTsIVmZCcZAgdnlI59MTpucttLM2Isbi820cWiQ65p3uZBEVsBkJcPrwfCpDCGo2s5JKAXf6FQGIA4vCikVwZDZD',
    dataType: 'json',
    success: function(json) {
      var html = '';
      var insta = json.media.data;
      for (var i = 0; i < insta.length; i++) {
        if (insta[i].media_url.match(/video-nrt/)) {
          html += '<ul><li><a class="over" href="' + insta[i].permalink + '" target="_blank"><video src="' + insta[i].media_url + '"></a></li></ul';
        } else {
          html += '<ul><li><a class="over" href="' + insta[i].permalink + '" target="_blank"><img src="' + insta[i].media_url + '"></a></li></ul';
        }
      }
      $("#instafeed").append(html);
    },
    error: function() {
      //エラー時の処理
    }
  });
});

/**
 * swiper.js
 * トップページ メインスライドを実行
 */

const primarySlide = new Swiper("#primarySlide", {
  effect: "fade",
  loop: true,
  slidesPerView: "auto",
  autoHeight: true,
  speed: 1000,
  autoplay: {
    delay: 2000,
    disableOnInteraction: false
  }
});
const primarySlide_sp = new Swiper("#primarySlide_sp", {
  effect: "fade",
  loop: true,
  slidesPerView: "auto",
  autoHeight: true,
  speed: 1000,
  autoplay: {
    delay: 2000,
    disableOnInteraction: false
  }
});

/**
 * swiper.js
 * トップページ サブスライドを実行
 */
const secondarySlide = new Swiper("#secondarySlide", {
  loop: true,
  slidesPerView: "auto",
  autoHeight: true,
  speed: 500,
  autoplay: {
    delay: 5000,
    disableOnInteraction: false
  },
  pagination: {
    el: ".swiper-pagination",
    clickable: true
  },
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
});

/**
 * swiper.js
 * 商品詳細 スライド
 */
const goodsDetailSlideArray = Array.prototype.slice.call(document.querySelectorAll("#goodsDetailSlide .swiper-wrapper .swiper-slide img"));
const goodsDetailSlide = new Swiper("#goodsDetailSlide", {
  slidesPerView: 'auto',
  effect: "fade",
  pagination: {
    el: ".swiper-pagination",
    clickable: true,
    renderBullet: function (index, className) {
      return `<span class="${className}"><img src="${goodsDetailSlideArray[index].src}" alt=""></span>`;
    }
  }
});

new Swiper(".swiper-ranking", {
  slidesPerView: "auto",
  autoHeight: true,
  speed: 500,
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
});

/**
 * レスポンシブ
 */
const mq = window.matchMedia('screen and (max-width: 850px)').matches;
if (!mq) {
  fixedHeader();
}

/**
 * ページがロードされたら実行
 */
window.addEventListener('load', function() {
  matchHeight("#goodsList .goods");
} , { passive: true });


primarySlide.constructor;
primarySlide_sp.constructor;
secondarySlide.constructor;
goodsDetailSlide.constructor;

slideMenu();
resizeEvent();

