import { matchHeight } from "./matchHeight";

export function resizeEvent() {
  let resizeTimer;

  window.addEventListener("resize", () => {
    if (resizeTimer !== null) {
      clearTimeout(resizeTimer);
    }

    resizeTimer = setTimeout(() => {
      matchHeight("#goodsList .goods");
    }, 100);
  });
}