export function matchHeight(targetElement) {
  const target = document.querySelectorAll(`${targetElement}`);
  const targetArray = [];

  for (let targetElm of target) {
    targetElm.style.height = "";
    targetArray.push(targetElm.clientHeight);
  }

  const maxHeight = Math.max(...targetArray);

  for (let targetElm of target) {
    targetElm.style.height = `${maxHeight}` + "px";
  }
}
